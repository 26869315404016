import React, { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  Drawer,
  IconButton,
  Avatar,
  Box,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import { useSocket } from '../../socket';

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '350px',
    backgroundColor: '#f5f5f5',
  },
  topHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    backgroundColor: '#4ea1d3',
    color: 'white',
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: '18px',
  },
  chatArea: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    maxWidth: '80%',
  },
  messageBubble: {
    padding: theme.spacing(1, 2),
    borderRadius: '18px',
    fontSize: '14px',
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
  messageTime: {
    fontSize: '10px',
    color: '#888',
    marginTop: theme.spacing(0.5),
  },
  senderMessageContainer: {
    alignSelf: 'flex-end',
  },
  senderMessageBubble: {
    backgroundColor: '#4ea1d3',
    color: 'white',
    borderBottomRightRadius: '4px',
  },
  receiverMessageContainer: {
    alignSelf: 'flex-start',
  },
  receiverMessageBubble: {
    backgroundColor: '#e0e0e0',
    color: 'black',
    borderBottomLeftRadius: '4px',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  messageAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  bottomInput: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    borderTop: '1px solid #e0e0e0',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
}));


const Chat = ({ display, roomId,onNew, onClose }) => {
  const classes = useStyles();
  const currentUser = localStorage.getItem('name');
  const currentId = localStorage.getItem('userId');

  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const { socket, isConnected, initializeSocket } = useSocket();

  useEffect(() => {
    console.log('SidebarLayout useEffect - socket state:', { isConnected, currentId });
    if (!isConnected && currentUser) {
      console.log('Initializing socket from SidebarLayout');
      initializeSocket(currentId);
    }
  }, [isConnected, currentId, initializeSocket]);

  useEffect(() => {
    if (isConnected && socket) {
      socket.on('FE-receive-message', ({ msg, sender, senderSocketId }) => {
        console.log('Received message from socket ID:', senderSocketId);
        onNew()
        setMessages((msgs) => [
          ...msgs,
          {
            sender,
            msg,
            isCurrentUser: senderSocketId === socket.id,
            timestamp: new Date(),
          },
        ]);
      });

      return () => {
        socket.off('FE-receive-message');
      };
    }
  }, [socket, isConnected]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
 
  const formatTime = (date) => {
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      month: 'short',
      day: 'numeric',
    });
  };

  const sendMessage = useCallback(() => {
    if (inputMessage.trim() && socket) {
      socket.emit('BE-send-message', { 
        roomId, 
        msg: inputMessage.trim(), 
        sender: currentUser,
        senderSocketId: socket.id
      });
      setInputMessage('');
      console.log("Sender socket ID:", socket.id);
    }
  }, [inputMessage, roomId, currentUser, socket]);

  const handleKeyUp = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  }, [sendMessage]);

  const renderMessage = ({ sender, msg, isCurrentUser, timestamp }, idx) => (
    <Box
      key={idx}
      className={`${classes.messageContainer} ${
        isCurrentUser ? classes.senderMessageContainer : classes.receiverMessageContainer
      }`}
    >
      {!isCurrentUser && (
        <Box className={classes.avatarContainer}>
          <Avatar
            className={classes.messageAvatar}
            src={`https://api.adorable.io/avatars/40/${sender}.png`}
          />
          <Typography variant="caption">{sender}</Typography>
        </Box>
      )}
      <Box
        className={`${classes.messageBubble} ${
          isCurrentUser ? classes.senderMessageBubble : classes.receiverMessageBubble
        }`}
      >
        <Typography variant="body2">{msg}</Typography>
      </Box>
      <Typography className={classes.messageTime} variant="caption">
        {formatTime(new Date(timestamp))}
      </Typography>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={display}
      onClose={onClose}
      classes={{ paper: classes.chatContainer }}
    >
      <Box className={classes.topHeader}>
        <Typography className={classes.headerTitle}>Group Chat Room</Typography>
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.chatArea}>
        {messages.map(renderMessage)}
        <div ref={messagesEndRef} />
      </Box>
      <Box className={classes.bottomInput}>
        <Avatar
          className={classes.messageAvatar}
          src={`https://api.adorable.io/avatars/40/${currentUser}.png`}
        />
        <TextField
          className={classes.input}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={handleKeyUp}
          placeholder="Type a message..."
          variant="outlined"
          size="small"
          fullWidth
        />
        <IconButton color="primary" onClick={sendMessage}>
          <SendIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
};

export default Chat;