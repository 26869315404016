import React, { useState, useRef,useEffect } from 'react';
import { Typography, Button, CircularProgress, Avatar, Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { useMessageListener } from '../firebaseConfig/OnMessageListner';
import { useServiceWorkerMessages } from '../firebaseConfig/useServiceWorkerMessages';
import call_ringtone from '../../assests/ringtone/outgoing.mp3';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    maxWidth: '400px',
    width: '100%',
    margin: 'auto',
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: theme.spacing(6),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  callingText: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  statusText: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  progress: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  icon: {
    fontSize: theme.spacing(4),
    animation: '$pulse 1.5s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      opacity: 0.7,
    },
    '50%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(0.95)',
      opacity: 0.7,
    },
  },
}));

const CallingScreen = ({ calleeName, onCancel, onCallAccepted, onCallRejected }) => {
  const classes = useStyles();
  const [callStatus, setCallStatus] = useState('calling');
  const callTimeout = 30000; // 30 seconds
  const { callAccepted, callRejected,resetCallAccepted,resetCallRejected } = useMessageListener();
  const message = useServiceWorkerMessages();
  const audioRef = useRef(null);
  const [countdown, setCountdown] = useState(30);
  useEffect(() => {
    audioRef.current = new Audio(call_ringtone);
    audioRef.current.loop = true;
    console.log("caller name in calling screen ",calleeName)

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch(() => {});
      const timer = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      return () => clearInterval(timer);
    } else if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setCountdown(30);
    }
  }, []);
 const stop=()=>{
  if (audioRef.current) {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  }
 }
 
 
  useEffect(() => {
    console.log("calling bg",message)
    if (message && message.action === 'CALL_ACCEPTED') {
      onCallAccepted(message.data.meetingId);
      stop()
    }
    else if (message && message.action === 'CALL_REJECTED') {
      onCallRejected(message.data)
      stop()
    }
  }, [message]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (callStatus === 'calling') {
        setCallStatus('no_answer');
        onCallRejected();
        stop()

      }
    }, callTimeout);

    return () => clearTimeout(timer);
  }, [callStatus, onCallRejected]);

  useEffect(() => {
      if (callAccepted) {
        setCallStatus('accepted');
        onCallAccepted(callAccepted);
        resetCallAccepted()
        stop()

      } else if (callRejected) {
        setCallStatus('rejected');
        onCallRejected();
        resetCallRejected()
        stop()

      }
    
  }, [ callAccepted, callRejected]);

  const getStatusText = () => {
    switch (callStatus) {
      case 'calling':
        return 'Calling...';
      case 'no_answer':
        return 'No Answer';
      case 'accepted':
        return 'Call Accepted';
      case 'rejected':
        return 'Call Rejected';
      default:
        return '';
    }
  };

  return (
    <Paper className={classes.root} elevation={3}>
      <Avatar className={classes.avatar}>
        {calleeName.charAt(0).toUpperCase()}
      </Avatar>
      <Typography variant="h5" className={classes.callingText}>
        {calleeName}
      </Typography>
      <Typography variant="body1" className={classes.statusText}>
        {getStatusText()}
      </Typography>
      {callStatus === 'calling' && (
        <CircularProgress size={40} thickness={4} className={classes.progress} />
      )}
      <Box className={classes.iconContainer}>
        {callStatus === 'calling' && <CallIcon className={classes.icon} color="primary" />}
        {callStatus === 'accepted' && <CallIcon className={classes.icon} style={{ color: 'green' }} />}
        {(callStatus === 'rejected' || callStatus === 'no_answer') && <CallEndIcon className={classes.icon} style={{ color: 'red' }} />}
      </Box>
      <Button
        variant="contained"
        color="secondary"
        onClick={onCancel}
        className={classes.button}
        startIcon={<CallEndIcon />} >
        End Call
      </Button>
    </Paper>
  );
};

export default CallingScreen;