import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Room from './components/Room/Room';
import styled from 'styled-components';
import Login from './components/Login/Login';
import SidebarLayout from './components/Main/SidebarLayout';
import IncomingCallNotification from './components/UserCall/IncomingCallNotification'
import axios from 'axios';
import { useMessageListener } from './components/firebaseConfig/OnMessageListner';
import { useServiceWorkerMessages } from './components/firebaseConfig/useServiceWorkerMessages';
import { SocketProvider } from './socket';

function App() {
  const [incomingCall, setIncomingCall] = useState(null);
  const [showError, setShowError] = useState(null);
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

  const { callNotification, error ,resetError,callRejected,callAccepted,resetCallAccepted,resetCallNotification, resetCallRejected } = useMessageListener();
  const message = useServiceWorkerMessages();
const userId = localStorage.getItem('userId')
  const isAuthenticated = () => {
    console.log(!!localStorage.getItem('token'))
    return !!localStorage.getItem('token');
  };

  const handleAcceptIncomingCall = async ( meetingId,caseId) => {
    try {
    
if(caseId){
const encodedJWT = encodeURIComponent(btoa(localStorage.getItem('token'))); 
const encodedID = encodeURIComponent(btoa(caseId));    

// Construct the URL with query parameters
const url = `https://admin.settlemed.in/Dashboard/EditCases/v2?token=${encodedJWT}&id=${encodedID}`;

// Open the new tab with the constructed URL
window.open(url, '_blank');
}

if (!userId) {
  console.error('userId is missing');
  setShowError('User ID is missing. Please try logging in again.');
  return;
}



      joinRoom(meetingId);

      setIncomingCall(null);
    } catch (error) {
      console.error('Error accepting call:', error);
      setShowError('Multi User Login Detected , Please try logging in again.');

    }
  };

  const acceptCall = async (callerId, meetingId,caseId) => {
    try {
      await axios.post(`${REACT_APP_API_URL}/notifications/accept-call`, {
       userId,
        callerId,
        meetingId,
      });
if(caseId){
const encodedJWT = encodeURIComponent(btoa(localStorage.getItem('token'))); 
const encodedID = encodeURIComponent(btoa(caseId));    

// Construct the URL with query parameters
const url = `https://admin.settlemed.in/dashboard/ActiveCases/v2?token=${encodedJWT}&caseId=${encodedID}`;

// Open the new tab with the constructed URL
window.open(url, '',"width=400,height=600");
}
      joinRoom(meetingId);

      setIncomingCall(null);
    } catch (error) {
      console.error('Error accepting call:', error);
    }
  };
  const joinRoom = (meetingId) => {
    console.log('Joining room with meeting ID:', meetingId);
    window.location.href = `/room?meetingId=${meetingId}`;
  };

  const rejectCall = async (callerId, meetingId) => {
    try {
      setIncomingCall(null);

      await axios.post(`${REACT_APP_API_URL}/notifications/reject-call`, {
        callerId,
        meetingId,
        userId
      }); 

    } catch (error) {
      console.error('Error rejecting call:', error);
    }
  };
// Inside your App component or appropriate context
useEffect(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.onmessage = (event) => {
        if (event.data && event.data.action === 'INCOMING_CALL') {
          const { payload } = event.data;
          console.log("Incoming call data:", payload);
          setIncomingCall(   payload.data.callerId,
           payload.data.callerName,
           payload.data.meetingId,
           payload.data.caseId)
        }
      };
    });
  }
}, []);
  useEffect(() => {
    console.log("Inside APP.js")
    if (callNotification) {
      setIncomingCall(callNotification)
      resetCallNotification();  
    } 
     if (callRejected) {
      
      setIncomingCall(null)
      resetCallRejected();
    }
    if(callAccepted){
      handleAcceptIncomingCall( incomingCall.meetingId,incomingCall.caseId)
      resetCallAccepted()
    }

  }, [callNotification, callRejected,callAccepted]);

  
  useEffect(() => {
    console.log("MEssgae APp",message)
    if (message && message.action === 'ACCEPT') {

      acceptCall(message.data.callerId, message.data.meetingId)
    }
    else if (message && (message.action === 'REJECT' ||message.action === 'CALL_REJECTED')) {
setIncomingCall(null)
    }else  if (message && (message.action === 'INCOMING_CALL')) {
        const payload = message.data.data;
        console.log("Incoming call data:", payload);
        setIncomingCall({
          callerId: payload.callerId,
          callerName: payload.callerName,
          meetingId: payload.meetingId,
          caseId :payload.caseId
      });
    } else if (message && message.data.type === 'INCOMING_CALL') {
    
    const payload = message.data
      setIncomingCall({
        callerId: payload.callerId,
        callerName: payload.callerName,
        meetingId: payload.meetingId,
        caseId :payload.contextId
    });
    }
    else if (message && message.data.type === 'CALL_REJECTED') {
      setIncomingCall(null)

    }
    
  }, [message]);

  return (
    <SocketProvider>

    <BrowserRouter>
      <AppContainer>
        <Switch>
          <Route exact path="/" render={() => (
            isAuthenticated() ? <Redirect to="/main" /> : <Login />
          )} />

          <Route path="/main" render={() => (
            isAuthenticated() ? <SidebarLayout /> : <Redirect to="/" />
          )} />
          <Route path="/schedulecalls" render={() => (
            isAuthenticated() ? <SidebarLayout /> : <Redirect to="/" />
          )} />
          <Route exact path="/room" render={() => (
            isAuthenticated() ? <Room /> : <Redirect to="/" />
          )} />
          <Route path="/callhistory" render={() => (
            isAuthenticated() ? <SidebarLayout /> : <Redirect to="/" />
          )} />
            <Route path="/contacts" render={() => (
            isAuthenticated() ? <SidebarLayout /> : <Redirect to="/" />
          )} />
        </Switch>
        {incomingCall && (
          <IncomingCallNotification
            open={true}
            caller={incomingCall.callerName}
            onAccept={() => acceptCall(incomingCall.callerId, incomingCall.meetingId,incomingCall.caseId)}
            onReject={() => rejectCall(incomingCall.callerId, incomingCall.meetingId)}
          />
        )}
         {showError && ( 
            <ErrorAlert>
              {showError}
              <button onClick={() => setShowError(null)}>Close</button>
            </ErrorAlert>
          )}
      </AppContainer>
    </BrowserRouter>
    </SocketProvider>
  );
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
  background-color: #454552;
  text-align: center;
`;
const ErrorAlert = styled.div`
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;
export default App;