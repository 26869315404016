import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton,Avatar } from '@material-ui/core';
import { Mic, MicOff } from '@material-ui/icons';
import VideoCard from '../Video/VideoCard';

const useStyles = makeStyles((theme) => ({
  videoGrid: {
    display: 'grid',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    height: 'calc(100vh - 64px - 56px)', // Subtract AppBar and BottomBar heights
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gridTemplateRows: 'auto',
  },
  videoItem: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      display: 'block',
    },
  },
  userName: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
  },
  audioIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  avatarContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    transition: 'opacity 0.3s ease-in-out',
  },
  avatar: {
    width: '30%',
    height: 'auto',
    aspectRatio: '1 / 1',
    fontSize: '3rem',
    backgroundColor: theme.palette.primary.main,
  },
}));

const VideoGrid = ({ peers, userVideoRef, currentUser, userVideoAudio }) => {
  const classes = useStyles();
  const [gridTemplate, setGridTemplate] = useState('');

  const getInitials = (name) => {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  };
  useEffect(() => {
    const totalParticipants = peers.length + 1; // Include local user
    let columns, rows;
   console.log ("video",userVideoAudio['localUser'].video)
    if (totalParticipants <= 2) {
      columns = totalParticipants;
      rows = 1;
    } else if (totalParticipants <= 4) {
      columns = 2;
      rows = 2;
    } else if (totalParticipants <= 9) {
      columns = 3;
      rows = 3;
    } else {
      columns = 4;
      rows = Math.ceil(totalParticipants / 4);
    }

    setGridTemplate(`repeat(${rows}, 1fr) / repeat(${columns}, 1fr)`);
  }, [peers]);
  const isVideoEnabled = userVideoAudio?.localUser?.video;
  const isAudioEnabled = userVideoAudio?.localUser?.audio;
  return (
    <div className={classes.videoGrid} style={{ gridTemplate }}>
      {/* {userVideoAudio['localUser'].video && ( */}
      <div className={classes.videoItem}>
        {isVideoEnabled ? (
          <video ref={userVideoRef} muted autoPlay playsInline style={{ opacity: 1 }} />
        ) : (
          <div
          className={classes.avatarContainer}
          style={{ opacity: isVideoEnabled ? 0 : 1 }}
        >
          <Avatar className={classes.avatar}>
            {getInitials(currentUser || 'User')}
          </Avatar>
        </div>
        )}
        <Typography variant="body2" className={classes.userName}>
          {currentUser} (You)
        </Typography>
        <IconButton className={classes.audioIcon} size="small">
          {isAudioEnabled ? <Mic /> : <MicOff />}
        </IconButton>
      </div>
      {/* )} */}
      {peers.map((peer, index) => (
        <div key={index} className={classes.videoItem}>
        
        {/* {userVideoAudio[peer.peerID]?.video ? ( */}
            <VideoCard peer={peer} isVideoEnable={userVideoAudio[peer.peerID]?.video} />
          {/* ) : (
            <Avatar className={classes.avatar}>{peer.userName[0]}</Avatar>
          )} */}
      
          <Typography variant="body2" className={classes.userName}>
            {peer.userName}
          </Typography>
          
          <IconButton className={classes.audioIcon} size="small">
         
            {userVideoAudio[peer.peerID]?.audio ? <Mic /> : <MicOff />}
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default VideoGrid;
