import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 0.3s ease-in-out',
  },
  videoError: {
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  avatarContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    transition: 'opacity 0.3s ease-in-out',
  },
  avatar: {
    width: '30%',
    height: 'auto',
    aspectRatio: '1 / 1',
    fontSize: '3rem',
    backgroundColor: theme.palette.primary.main,
  },
  userName: {
    marginTop: theme.spacing(2),
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  micStatus: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const VideoCard = ({ peer, isVideoEnable, isMicrophoneEnable }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    if (peer) {
      const handleStream = (stream) => {
        if (ref.current) {
          setStream(stream);
          ref.current.srcObject = stream;
          setLoading(false);
          setError(false);
        }
      };

      const handleError = () => {
        setError(true);
        setLoading(false);
      };

      peer.on('stream', handleStream);
      peer.on('error', handleError);

      return () => {
        peer.off('stream', handleStream);
        peer.off('error', handleError);
      };
    }
  }, [peer]);

  useEffect(() => {
    if (ref.current && stream) {
      // Manage video track instead of setting the whole stream to null
      const videoTracks = stream.getVideoTracks();
      if (videoTracks.length > 0) {
        videoTracks[0].enabled = isVideoEnable; // Enable or disable the video track
      }
    }
  }, [isVideoEnable, stream]);

  const getInitials = (name) => {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  };

  return (
    <div className={classes.container}>
      {loading && <CircularProgress className={classes.loading} />}
      {error ? (
        <div className={classes.videoError}>Video failed to load</div>
      ) : (
        <>
          <video
            ref={ref}
            autoPlay
            playsInline
            className={classes.video}
            style={{ opacity: isVideoEnable ? 1 : 0 }}
            aria-label="Video Stream"
          />
          <div
            className={classes.avatarContainer}
            style={{ opacity: isVideoEnable ? 0 : 1 }}
          >
            <Avatar className={classes.avatar}>
              {getInitials(peer.userName || 'User')}
            </Avatar>
            <div className={classes.userName}>{peer.userName || 'User'}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default VideoCard;