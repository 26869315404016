import React, { useState, useEffect,useCallback  } from 'react';
import { 
  Button, Grid, List, ListItem, ListItemText, Snackbar, Card, Typography, 
  CircularProgress, Avatar, IconButton, InputAdornment, TextField
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import PhoneIcon from '@material-ui/icons/Phone';
import debounce from 'lodash/debounce'
import CallingScreen from '../UserCall/CallingScreen';
import { useServiceWorkerMessages } from '../firebaseConfig/useServiceWorkerMessages';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  contactsContainer: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    flexGrow: 1,
    overflow: 'auto',
    height: '70vh',  // Set a height to make the container scrollable
    marginTop: theme.spacing(2),
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  card: {
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  callButton: {
    minWidth: 'unset',
    padding: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  search: {
    marginBottom: theme.spacing(2),
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const [calling, setCalling] = useState(false);
  const [callee, setCallee] = useState(null);
  const [calleeId, setCalleeId] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const message = useServiceWorkerMessages();
  const [meetingDetails, setMeetingDetails] = useState(null);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${REACT_APP_API_URL}/api/auth/getContactList`, {
        headers: { 'Content-Type': 'application/json' },
      });
      setUsers(response.data);
      setFilteredUsers(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching users', error);
      setError('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchUsers();
    }, []);
  const filterUsers = useCallback(
    debounce((searchTerm) => {
      const filtered = users.filter(user => {
        const searchLower = searchTerm.toLowerCase();
        const fullName = user.fullName ? user.fullName.toLowerCase() : '';
        const email = user.email ? user.email.toLowerCase() : '';
        return fullName.includes(searchLower) || email.includes(searchLower);
      });
      setFilteredUsers(filtered);
    }, 300),
    [users]
  );
  useEffect(() => {
    if (message && message.data.type === "MEETING_CREATED") {
      console.log("contact bg inside")
      console.log("contact bg ", message.data.meetingId)

      setMeetingId(message.data.meetingId)

    }
   
  }, [message,meetingId]);
  useEffect(() => {
    filterUsers(searchTerm);
  }, [searchTerm, filterUsers]);
  const handleCallUser = async (userId) => {
    const callee = users.find(user => user.id === userId);

    setCallee(callee);
    setCalleeId(userId)
    setCalling(true);
    showSnackbar('Call initiated successfully', 'success');
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/notifications/initiate-call`,
        { 
          userId, 
          callerId: localStorage.getItem('userId'),
          callerName: localStorage.getItem('name'),
          role:"Command Center" 
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 200) {
        
       
      } else {
        showSnackbar('Failed to initiate call', 'error');
      }
    } catch (error) {
      console.error('Error initiating call:', error);
      showSnackbar('Error initiating call', 'error');
    }
  };
  const handleCallRejected = () => {
    showSnackbar('Call was Decline', 'warning');
    setCalling(false);
    setCallee(null);
  };      
  const userId = localStorage.getItem('userId')

 
  const handleCancelCall = async() => {
    await axios.post(`${REACT_APP_API_URL}/notifications/reject-call`, {
      callerId:calleeId,
      meetingId:meetingId,
      userId
    }); 

    setCalling(false);
    setCallee(null);
    showSnackbar('Call Rejected', 'info');
  };

  const handleCallAccepted = (meetingId) => {
    console.log('Call accepted, joining room...');
    showSnackbar('Call accepted, joining room...', 'success');
    setCalling(false);
    setCallee(null);
    window.location.href = `/room?meetingId=${meetingId}`;    
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

 

  return (
    <div className={classes.root}>
 {calling ? (
        <CallingScreen
          calleeName={callee?.fullName || callee || "User"}
          onCancel={handleCancelCall}
          onCallAccepted={handleCallAccepted}
          onCallRejected={handleCallRejected}
        />
      ) : (

      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6} lg={4} className={classes.contactsContainer}>
          <Typography variant="h4" className={classes.title}>
            Contacts
          </Typography>
          <TextField
            className={classes.search}
            variant="outlined"
            fullWidth
            placeholder="Search contacts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {loading && <CircularProgress />}
          {error && (
            <MuiAlert severity="error" style={{ marginBottom: '16px' }}>
              {error}
            </MuiAlert>
          )}
          <div className={classes.listContainer}>
            <List>
              {filteredUsers.map((user) => (
                user.fullName && (
                  <Card key={user.id} className={classes.card}>
                    <ListItem>
                      <Avatar className={classes.avatar}>
                        {user.fullName.charAt(0)}
                      </Avatar>
                      <ListItemText 
                        primary={user.fullName} 
                        secondary={user.email} 
                        primaryTypographyProps={{ variant: 'h6' }}
                        secondaryTypographyProps={{ variant: 'body2' }}
                      />
                      <IconButton
                        color="primary"
                        onClick={() => handleCallUser(user.id)}
                        className={classes.callButton}
                      >
                        <PhoneIcon />
                      </IconButton>
                    </ListItem>
                  </Card>
                )
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
         )}
       
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Contacts;
