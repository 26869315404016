import React, { useRef, useState, useEffect } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import {
  AppBar, Toolbar, Typography, Box, Drawer, List, ListItem,
  ListItemIcon, ListItemText, Divider, makeStyles, CssBaseline,
  Grid, Paper, Button, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, IconButton, Menu, MenuItem, Avatar
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GroupIcon from '@material-ui/icons/Group';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import {useSocket} from '../../socket';
import Main from './Main';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import FullCalendarComponent from '../schduler/FullCalendarComponent';
import Contacts from '../Contacts/contacts';
import Logo from '../../assests/images/Logo.png'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import axios from 'axios';
import { requestForToken } from '../firebaseConfig/firebase';
const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: '#ffffff',
    color: '#000000'
  },
  logo: {
    height: 40,
    marginRight: theme.spacing(2),
  },
  logoutButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
  },
  grow: {
    flexGrow: 1,
  },
  meetingButton: {
    marginLeft: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(8), // Offset to account for the AppBar height
  },
  list:{
color :'#000000'
  },
  appbar:{
    backgroundColor :'#ffffff',
    color :'#000000'
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%', // Ensure the Paper component takes full height
  },
   textStyle : {
    color: '#797979',  // Text color (white)
    textAlign: 'left', // Align text to the left
    fontSize: '16px',  // Font size (16 pixels)
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  drawerGrid: {
    backgroundColor :"#ffffff"
  },
  toolbar: theme.mixins.toolbar, // Ensures correct spacing for the AppBar
 
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  meetingButton: {
    margin: theme.spacing(1),
    borderRadius: 20,
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  createMeetingButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  joinMeetingButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  }
  
}));

const SidebarLayout = () => {
  const classes = useStyles();
  const [isJoining, setIsJoining] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const roomRef = useRef({ value: '' });
  const history = useHistory();
  const userId = localStorage.getItem('userId');
  const { socket, isConnected, initializeSocket } = useSocket();
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const [fcmToken, setFcmToken] = useState(null);
  const SETTLEMED_API_URL = "https://api.settlemed.in";
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null); // State to store user data

  useEffect(() => {
    const storedToken = localStorage.getItem('FCM');
    if (storedToken) {
      setFcmToken(storedToken);
    }
  }, []);

  useEffect(() => {
    console.log('SidebarLayout useEffect - socket state:', { isConnected, userId });
    if (!isConnected && userId) {
      console.log('Initializing socket from SidebarLayout');
      initializeSocket(userId);
    }
  }, [isConnected, userId, initializeSocket]);
  useEffect(() => {
    console.log('SidebarLayout useEffect - setting up event listeners');
    if (isConnected && socket) {
      console.log('Socket connected and available:', socket);

      const handleUserExist = ({ error }) => {
        console.log('FE-error-user-exist event received');
        if (error) {
          setErr(true);
          setErrMsg('User name already exists');
        }
      };

      const handleRoomCreated = (roomId) => {
        console.log('FE-room-created event received:', roomId);
        createJoinMeeting(userId, roomId);
      };

      socket.on('FE-error-user-exist', handleUserExist);
      socket.on('FE-room-created', handleRoomCreated);

      return () => {
        console.log('Removing socket event listeners');
        socket.off('FE-error-user-exist', handleUserExist);
        socket.off('FE-room-created', handleRoomCreated);
      };
    } else {
      console.log('Socket not connected or not available');
    }
  }, [isConnected, socket, userId]);

  const handleCreateMeeting = () => {
    console.log('handleCreateMeeting called');
    if (socket && isConnected) {
      console.log('Emitting BE-create-room event');
      socket.emit('BE-create-room');
    } else {
      console.error('Socket is not connected');
    }
  };

  useEffect(()=>{
    var token = localStorage.getItem('FCM');

    const requestAndUpdateToken = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          let token = fcmToken;
          if (!token) {
            console.log("Requesting new FCM token");
            token = await requestForToken();
            if (token) {
              setFcmToken(token);
              localStorage.setItem('FCM', token);
            } else {
              console.warn('Failed to retrieve FCM token');
            }
          }
          
          if (token) {
            console.log("Updating FCM token on the server");
            await updateFirebaseId(token);
          }
        } else {
          console.warn('Notification permission denied');
        }
 
      } catch (error) {
   
      }
    };
    if(!token){
    requestAndUpdateToken()
  }
  },[])

  const handleJoinMeeting = () => {
    setIsJoining(true);
  };
 
  const joinMeeting = () => {
    const roomId = roomRef.current.value;
    if (!roomId) {
      setErr(true);
      setErrMsg('Enter Room Name');
      return;
    }

    createJoinMeeting(userId, roomId);
  };

  const updateFirebaseId = async (token) => {
    try {
      await axios.patch(
        `${SETTLEMED_API_URL}/update-firebaseId`,
        { firebaseId: token },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token')
          },
        }
      );
    } catch (error) {
      console.error('Error updating Firebase ID:', error);
    }
  };

  const handleLogout = async() => {
    console.log('Logging out...');
    try {
      // Make a POST request to the logout endpoint
      const userId = localStorage.getItem('userId')
      const response = await axios.post(`${REACT_APP_API_URL}/api/auth/logout`, 
        { userId });
  
      if (response.status === 200) {
   localStorage.clear();
    
   if (socket && socket.disconnect) {
     socket.disconnect();
   }
   
   // Navigate to the root path
   history.replace('/');
           console.log('Logout successful:', response.data.message);
        
        // Redirect or perform other actions as needed
      } else {
        console.log('Logout failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
 
  };
  const createJoinMeeting = (userId, roomId) => {
    if (!userId) {
      setErr(true);
      setErrMsg('User ID not found. Please log in again.');
      return;
    }
    history.push({ pathname: '/room', state: { roomName: roomId } });
  };
  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar>
                     <img src={Logo} alt="Logo" className={classes.logo} />
                     <div className={classes.grow} />
                     <Button
            variant="contained"
            className={`${classes.meetingButton} ${classes.createMeetingButton}`}
            startIcon={<AddIcon />}
            onClick={handleCreateMeeting}
          >
            Create Meeting
          </Button>
          <Button
            variant="contained"
            className={`${classes.meetingButton} ${classes.joinMeetingButton}`}
            startIcon={<GroupIcon />}
            onClick={handleJoinMeeting}
          >
            Join Meeting
          </Button>
          <IconButton
            className={classes.logoutButton}
            onClick={handleLogout}
            title="Logout"
          >
            <ExitToAppIcon />
            </IconButton>
            <IconButton
            onClick={handleProfileClick}
            className={classes.profileButton}
            color="inherit"
          >
          
          
          <Box display="flex" alignItems="center" padding="8px">
  <AccountCircleIcon style={{ color: '#797979' }} />
  <Typography variant="body1" style={{ color: '#797979', fontSize: '16px', paddingLeft: '10px' }}>
    {localStorage.getItem('name') || 'User'}
  </Typography>
</Box>

            
         
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={2} className={classes.drawerGrid}>
          <Box
            className={classes.drawer}
            variant="permanent"
            classes={{ paper: classes.drawerPaper }}
            anchor="left"
          >
 <Typography variant="body1" style={{ color: '#797979', textAlign: 'left', fontSize: '16px' , paddingLeft:'15px',paddingTop :'15px'}}>
MAIN 
   </Typography>
<List className={classes.list}>
              {['Main Menu'].map((text, index) => (
                <ListItem button key={text} component={Link} to={index === 0 ? '/main' : `/${text.toLowerCase().replace(' ', '')}`} className={classes.link}>
                  <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                  <ListItemText primary={text} />
                  
                </ListItem>
              ))}
            </List>
            <Divider/>
            <Typography variant="body1" style={{ color: '#797979', textAlign: 'left', fontSize: '16px' , paddingLeft:'15px',paddingTop :'15px'}}>
SCHEDULES 
   </Typography>
            <List className={classes.list}>
              {['Contacts', 'Schedule Calls', 'Call History'].map((text, index) => (
                <ListItem button key={text} component={Link} to={index === 0 ? '/contacts' : `/${text.toLowerCase().replace(' ', '')}`} className={classes.link}>
                  <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                  <ListItemText primary={text} />
                  
                </ListItem>
              ))}
            </List>
            <Divider/>
            <Typography variant="body1" style={{ color: '#797979', textAlign: 'left', fontSize: '16px' , paddingLeft:'15px',paddingTop :'15px'}}>
SETTINGS 
   </Typography>
   <List className={classes.list}>
              {['Settings'].map((text, index) => (
                <ListItem button key={text} component={Link} to={index === 0 ? '/main' : `/${text.toLowerCase().replace(' ', '')}`} className={classes.link}>
                  <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                  <ListItemText primary={text} />
                  
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        
        <Grid item xs={10} className={classes.gridItem}>
          <Paper className={classes.paper}>
            <Switch>
              <Route exact path="/main">
                <Main />
              </Route>
              <Route exact path="/schedulecalls">
                <FullCalendarComponent />
              </Route>
              {/* Uncomment and add your routes for SchedulePage and HistoryPage */}
              {/* {/* <Route exact path="/schedulemeeting"> */}
                {/* <ScheduleMeeting /> */}
                <Route exact path="/contacts">
                <Contacts />
              </Route> 
             
            </Switch>
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={isJoining} onClose={() => setIsJoining(false)}>
        <DialogTitle>Join Meeting</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="roomName"
            label="Room Name"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={roomRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsJoining(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={joinMeeting} color="primary">
            Join
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SidebarLayout;
